<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-width-1-1 uk-flex uk-flex-middle">
      <div class="uk-margin-right">
        <img
          :src="`${images}/icon/ic_arrow_left_black.svg`"
          class="cursor-pointer"
          alt=""
          @click.prevent="$router.push({ name: 'PurchaseOrderFarmer'})"
        >
      </div>
      <div>
        <h1 class="uk-heading-line">
          {{ headerTitle }}
        </h1>
      </div>
    </div>

    <detail
      :images="images"
      :data-detail="dataPoFarmerDetail"
    />

    <div class="uk-card uk-card-default uk-margin-medium-top base-card">
      <product-type
        :data-detail="dataPoFarmerDetail"
      />
    </div>

    <modal-add-edit-discard 
      :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
      :handle-modal-button="handleModalSave"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import Detail from './Detail'
import ProductType from './ProductType.vue'
import getHeaderTitle from '@/utils/header-title'

export default {
  components: {
    Detail,
    ProductType
  },
  data() {
    return {
      images: PREFIX_IMAGE
    }
  },
  computed: {
    ...mapGetters({
      dataPoFarmerDetail: 'purchaseOrder/dataPoFarmerDetail'
    }),
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  async mounted() {
    await this.getPoFarmerDetail(this.$route.params.id)
  },
  methods: {
    ...mapActions({
      getPoFarmerDetail: 'purchaseOrder/getPoFarmerDetail'
    })
  }
}
</script>

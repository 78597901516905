<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-top base-card">
      <div class="uk-form-stacked">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  PO Number
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="dataDetail.purchase_order_code"
                    name="partner_name"
                    class="uk-input"
                    type="text"
                    disabled
                  >
                </div>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Farmer Name
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="dataDetail.farmer.name"
                    class="uk-input"
                    type="text"
                    disabled
                  >
                </div>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  PO Date
                </label>
                <div class="uk-inline uk-width-1-1">
                  <img
                    :src="`${images}/icon/ic_calendar.svg`"
                    alt=""
                    class="uk-form-icon uk-form-icon-flip"
                  >
                  <input
                    :value="dateStringSlash(dataDetail.purchase_order_date)"
                    class="uk-input"
                    type="text"
                    disabled
                  >
                </div>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Facility Code
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="dataDetail.facility_code"
                    class="uk-input"
                    type="text"
                    placeholder="EVC-XXX"
                    disabled
                  >
                </div>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Species
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="dataDetail.species.name"
                    class="uk-input"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div>
          </div>
          
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Farmer Type
                </label>
                <div class="uk-form-controls">
                  <input
                    :value="dataDetail.farmer_type?.name || '-'"
                    class="uk-input"
                    type="text"
                    disabled
                  >
                </div>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Total Weight
                </label>
                <div class="uk-form-controls">
                  <input
                    :value="dataDetail.total_weight + ' Kg'"
                    class="uk-input"
                    type="text"
                    disabled
                  >
                </div>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Received PO Date
                </label>
                <div class="uk-inline uk-width-1-1">
                  <img
                    :src="`${images}/icon/ic_calendar.svg`"
                    alt=""
                    class="uk-form-icon uk-form-icon-flip"
                  >
                  <input
                    :value="dateStringSlash(dataDetail.purchase_order_received_date)"
                    class="uk-input"
                    type="text"
                    disabled
                  >
                </div>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Facility Type
                </label>
                <div class="uk-form-controls">
                  <input
                    :value="dataDetail.facility_type?.name || '-'"
                    class="uk-input"
                    type="text"
                    disabled
                  >
                </div>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Status
                </label>
                <div v-if="dataDetail.status === true">
                  <label-status
                    status="active"
                    label="Open"
                    table="false"
                  />
                </div>
                <div v-else-if="dataDetail.status === false">
                  <label-status
                    status="inactive"
                    label="Closed"
                    table="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateString } from '@/utils/formater'
import LabelStatus from '@/components/globals/LabelStatus'

export default {
  components: {
    LabelStatus
  },
  props: {
    images: {
      required: true,
      type: String
    },
    dataDetail: {
      required: true,
      type: Object
    }
  },
  methods: {
    dateStringSlash(d){
      const dateComponents = dateString(d).replaceAll('-', '/').split("/")
      return `${dateComponents[2]}/${dateComponents[1]}/${dateComponents[0]}`
    }
  }
}
</script>

<style scoped>
  .uk-input:disabled{
    background: #F4F4F4 !important;
    color: #000000 !important;
  }
  .uk-form-icon {
    width: 24px;
    height: 24px;
    top: 25%;
    z-index: 1;
  }
  .uk-form-icon-flip {
    right: 10px;
    left: auto;
  }
</style>

<template>
  <div>
    <h4 class="uk-heading-line">
      Product Type
    </h4>
    <div class="uk-margin-top">
      <div class="uk-overflow-auto border-table">
        <table
          class="uk-table uk-table-divider uk-table-middle uk-table-hover"
          aria-describedby="hulling-activity-table"
        >
          <thead class="thead-table-varion">
            <tr>
              <th
                class="uk-width-small"
              >
                <div>
                  <span>No</span>
                </div>
              </th>
              <th
                class="uk-table-expand"
              >
                <div>
                  <span>Varietas</span>
                </div>
              </th>
              <th
                class="uk-table-expand"
              >
                <div>
                  <span>Berat</span>
                </div>
              </th>
              <th
                class="uk-table-expand"
              >
                <div>
                  <span>Penerimaan</span>
                </div>
              </th>
            </tr>
          </thead>
          <template v-if="loadingTable">
            <loading-table :colspan="4" />
          </template>
          <template v-else-if="dataDetail.products && dataDetail.products.length > 0">
            <tbody>
              <tr
                v-for="(content, index) in dataDetail.products"
                :key="index+=1"
              >
                <td class="'uk-text-center'">
                  {{ index || '-' }}
                </td>
                <td class="'uk-text-center'">
                  {{ content.variety.name || '-' }}
                </td>
                <td class="'uk-text-center'">
                  {{ content.weight || '-' }}
                </td>
                <td class="'uk-text-center'">
                  {{ content.received_weight || '-' }}
                </td>
              </tr>
            </tbody>
          </template>
          <template v-else>
            <empty-table
              :colspan="4"
            />
          </template>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyTable from "@/components/globals/table/EmptyTable"
import LoadingTable from "@/components/globals/table/LoadingTable"
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    EmptyTable,
    LoadingTable
  },
  props: {
    dataDetail: {
      required: true,
      type: Object
    }
  },
  data(){
    return{
      loadingTable: true
    }
  },
  watch:{
    dataDetail(){
      if(this.dataDetail.products){
        this.loadingTable = false
      }
    }
  },
  methods: {
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    }
  }
}
</script>
